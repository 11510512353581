import {
  isBrowser,
  loadingStartAction,
  loadingSuccessAction,
} from "@wisr/common";
import { Loader, Wrapper } from "@wisr/react-ui";
import { navigate } from "gatsby";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { User } from "oidc-client";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LoginError } from "../../shared/errors/login-error";
import { appInsights } from "../../shared/insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  LOGIN_ERROR,
  LOGIN_ERROR_TYPE,
  MAX_RETRIES_REACHED,
  SERVER_ERROR,
  USER_DETAILS_ERROR,
  USER_NOT_FOUND,
  USER_PROFILE_ERROR,
} from "../../shared/insights/app-insights.consts";
import { createAppInsightsEvent } from "../../shared/insights/app-insights.utils";
import { AppInsightsError } from "../../types/analytics";
import { UserMetaData } from "../../user/user";
import { userGetSuccessAction } from "../../user/user.actions";
import { getLdUser, isWebviewCallHandlerDefined } from "../auth.utils";
import AuthService from "./login-auth.service";
import { useLoginUser } from "./login.hooks";
import { Buffer } from "buffer";
import { LoadingMessage } from "./login-auth.consts";
import { amplitudeHelper } from "../../shared/analytics/analytics.utils";

export const LoginAuthComponent = () => {
  const dispatch = useDispatch();
  const [oidcUser, setOidcUser] = useState<User | null>(null);
  const [userProfile, userProfileError] = useLoginUser(oidcUser);
  const [loginError, setLoginError] = useState<any | string | undefined>(
    undefined
  );
  loadingStartAction("Login");
  const MAX_RETRY_ATTEMPTS = 3;
  const [retryAttempts] = useState(
    (isBrowser() && Number(window.sessionStorage.getItem("loginAttempts"))) || 0
  );
  const [loadingMessage, setLoadingMessage] = useState(
    LoadingMessage.DASHBOARD
  );
  const ldClient = useLDClient();

  React.useEffect(() => {
    if (loginError && retryAttempts <= MAX_RETRY_ATTEMPTS) {
      const appInsightsError: AppInsightsError = {
        category: LOGIN_ERROR,
        info: loginError,
        type: LOGIN_ERROR_TYPE,
      };
      appInsights.trackEvent(
        EXCEPTION_ERROR_EVENT,
        createAppInsightsEvent(appInsightsError)
      );
      amplitudeHelper.sendEvent(LOGIN_ERROR_TYPE, {
        "Error type": loginError,
      });
      window.sessionStorage.setItem(
        "loginAttempts",
        (retryAttempts + 1).toString()
      );
      console.error(loginError);
      AuthService.signinRedirect();
    }
  }, [loginError, retryAttempts]);

  const handleRedirect = React.useCallback(
    (user: UserMetaData) => {
      if (isWebviewCallHandlerDefined() && user.refresh_token) {
        window.flutter_inappwebview.callHandler(
          "PrintRefreshToken",
          user.refresh_token
        );
      }
      const redirect = window.sessionStorage.getItem("redirectUrl") || null;
      const loanIdParam = window.sessionStorage.getItem("loanId");
      if (
        !user.hasCreditAccount &&
        user.loanId === 0 &&
        user.applicationCount === 0
      ) {
        navigate("/credit/create-profile", {
          state: { login: true },
        });
      } else if (loadingMessage === LoadingMessage.APPLICATION && loanIdParam) {
        navigate(`/application?loanid=${loanIdParam}`);
      } else if (redirect && !redirect.includes("silent-refresh")) {
        window.location.href = redirect;
        window.sessionStorage.removeItem("redirectUrl");
      } else {
        navigate("/", {
          state: { login: true },
        });
      }
    },
    [loadingMessage]
  );

  const initUser = React.useCallback(() => {
    const ldUser = getLdUser();
    if (ldClient && ldUser) {
      ldClient.identify(ldUser);
    }
  }, [ldClient]);

  React.useEffect(() => {
    AuthService.getUser()?.then((possibleUser) => {
      if (possibleUser?.token_type === "refresh_token") {
        AuthService.signinSilentCallback()
          .then(() => {
            if (window.sessionStorage.getItem("loanId")) {
              setLoadingMessage(LoadingMessage.APPLICATION);
            }
            AuthService.getUser()?.then((user) => {
              if (user?.access_token) {
                const claims = JSON.parse(
                  Buffer.from(
                    user.access_token.split(".")[1],
                    "base64"
                  ).toString()
                );
                const updatedUser = { ...user, profile: claims } as User;
                setOidcUser(updatedUser);
              } else {
                setOidcUser(user);
              }
            });
          })
          .catch((err) => {
            setLoginError(err);
          });
      } else if (possibleUser && possibleUser.access_token !== "") {
        if (window.sessionStorage.getItem("loanId")) {
          setLoadingMessage(LoadingMessage.APPLICATION);
        }
        AuthService.renewToken()?.then((user) => {
          const claims = JSON.parse(
            Buffer.from(user.access_token.split(".")[1], "base64").toString()
          );
          const updatedUser = { ...user, profile: claims } as User;
          setOidcUser(updatedUser);
        });
      } else {
        AuthService.signinRedirectCallback()
          .then((user) => {
            if (user) {
              setOidcUser(user);
            } else {
              setLoginError(USER_NOT_FOUND);
            }
          })
          .catch((err) => {
            setLoginError(err);
          });
      }
    });
  }, []);

  React.useEffect(() => {
    if (userProfile) {
      dispatch(userGetSuccessAction(userProfile));
      loadingSuccessAction("Login");
      initUser();
      handleRedirect(userProfile);
    }
  }, [dispatch, userProfile, handleRedirect, initUser]);

  React.useEffect(() => {
    if (userProfileError) {
      setLoginError(USER_PROFILE_ERROR);
      window.sessionStorage.setItem("errorType", USER_PROFILE_ERROR);
    }
  }, [userProfileError]);

  if (retryAttempts > MAX_RETRY_ATTEMPTS) {
    const isUserProfileError =
      window.sessionStorage.getItem("errorType") === USER_PROFILE_ERROR;
    const error: AppInsightsError = {
      category: SERVER_ERROR,
      info: `${retryAttempts} attempts`,
      type: isUserProfileError ? USER_DETAILS_ERROR : MAX_RETRIES_REACHED,
    };
    appInsights.trackEvent(
      EXCEPTION_ERROR_EVENT,
      createAppInsightsEvent(error)
    );
    window.sessionStorage.setItem(
      "loginAttempts",
      MAX_RETRY_ATTEMPTS.toString()
    );
    throw new LoginError(
      isUserProfileError ? USER_DETAILS_ERROR : MAX_RETRIES_REACHED
    );
  }

  return (
    <Wrapper>
      <Loader loading type="complex" loadingMessage={loadingMessage} />
    </Wrapper>
  );
};
