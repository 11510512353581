import { LoadingError, useLoadingStatus } from "@wisr/common";
import { User } from "oidc-client";
import React from "react";
import { useDispatch } from "react-redux";
import { creditProfileGetAction } from "../../redux/credit-profile/credit-profile.actions";
import { GETPROFILE_CREDIT_LOADING } from "../../redux/credit-profile/credit-profile.epic";
import { useGetCreditProfile } from "../../redux/credit-profile/credit-profile.hooks";
import { wisrProfileGetAction } from "../../redux/wisr-profile/wisr-profile.actions";
import { GETRPROFILE_WISR_LOADING } from "../../redux/wisr-profile/wisr-profile.epic";
import { useGetWisrProfile } from "../../redux/wisr-profile/wisr-profile.hooks";
import {
  EXCEPTION_ERROR_EVENT,
  LOGIN_ERROR,
  USER_DETAILS_ERROR,
} from "../../shared/insights/app-insights.consts";
import { AppInsightsError } from "../../types/analytics";
import { UserMetaData } from "../../user/user";
import { loanBorrowingGetAction } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.actions";
import { GETLOAN_BORROWING_LOADING } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.epic";
import {
  useGetIncompleteApplications,
  useGetLoans,
} from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";
import { appInsights } from "../../shared/insights/app-insights";
import { createAppInsightsEvent } from "../../shared/insights/app-insights.utils";
import { amplitudeHelper } from "../../shared/analytics/analytics.utils";

export const useLoginUser = (oidcUser: User | null) => {
  const [user, setUser] = React.useState<UserMetaData | null | undefined>(
    undefined
  );
  const [userError, setUserError] = React.useState<string | undefined>(
    undefined
  );
  const [, loansLoadingError] = useLoadingStatus(GETLOAN_BORROWING_LOADING);
  const [, wisrProfileLoadingError] = useLoadingStatus(
    GETRPROFILE_WISR_LOADING
  );
  const [, creditProfileLoadingError] = useLoadingStatus(
    GETPROFILE_CREDIT_LOADING
  );

  const dispatch = useDispatch();

  const loans = useGetLoans();
  const applications = useGetIncompleteApplications();
  const creditProfile = useGetCreditProfile();
  const wisrProfile = useGetWisrProfile();

  React.useEffect(() => {
    if (oidcUser) {
      dispatch(loanBorrowingGetAction());
      dispatch(creditProfileGetAction());
      dispatch(wisrProfileGetAction());
    }
  }, [dispatch, oidcUser]);

  React.useEffect(() => {
    if (
      loansLoadingError ||
      wisrProfileLoadingError ||
      creditProfileLoadingError
    ) {
      const error =
        loansLoadingError?.message ||
        wisrProfileLoadingError?.message ||
        creditProfileLoadingError?.message ||
        "Unable to load user data";

      const appInsightsError: AppInsightsError = {
        category: LOGIN_ERROR,
        info: getLoadingErrorInfo(
          loansLoadingError,
          wisrProfileLoadingError,
          creditProfileLoadingError
        ),
        type: "LoginHookError",
      };
      appInsights.trackEvent(
        EXCEPTION_ERROR_EVENT,
        createAppInsightsEvent(appInsightsError)
      );
      amplitudeHelper.sendEvent(USER_DETAILS_ERROR, {
        "Loans Loading Error": loansLoadingError?.message,
        "Wisr Profile Loading Error": wisrProfileLoadingError?.message,
        "Credit Profile Loading Error": creditProfileLoadingError?.message,
      });

      if (wisrProfileLoadingError || creditProfileLoadingError) {
        setUserError(error);
      }
    }
  }, [loansLoadingError, wisrProfileLoadingError, creditProfileLoadingError]);

  React.useEffect(() => {
    if (loans && applications && creditProfile && wisrProfile && oidcUser) {
      const hasProfile: boolean = creditProfile.hasProfile;
      const loanId = loans[0]?.loanId || 0;
      const loanCount = loans.length;

      const applicationCount = applications.length;
      const userProfile: UserMetaData = {
        ...oidcUser,
        hasCreditAccount: hasProfile,
        loanId,
        loanCount,
        applicationCount,
        partnerReferrer: creditProfile.partnerReferrer || "",
        expired: false,
        info: {
          firstName: wisrProfile.firstName,
          lastName: wisrProfile.lastName,
        },
        toStorageString: /* istanbul ignore next */ () =>
          JSON.stringify(oidcUser),
      };
      setUser(userProfile);
    }
  }, [loans, applications, wisrProfile, creditProfile, oidcUser]);

  return [user, userError] as const;
};

const getLoadingErrorInfo = (
  loansLoadingError?: LoadingError,
  wisrProfileLoadingError?: LoadingError,
  creditProfileLoadingError?: LoadingError
) => {
  const errorInfoList = [];
  if (loansLoadingError) {
    errorInfoList.push("Loans loading error");
  }
  if (wisrProfileLoadingError) {
    errorInfoList.push("Wisr profile loading error");
  }
  if (creditProfileLoadingError) {
    errorInfoList.push("Credit profile loading error");
  }

  return errorInfoList.toString();
};
